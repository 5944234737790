<template>
  <validation-observer
    ref="refFormObserver"
    #default="{invalid,pristine}"
  >
    <b-card id="find-booking-by-pnr-code-page">
      <div>
        <h4 class="text-heading-3 m-0">
          {{ $t('reservation.findBookingByPnrCode') }}
        </h4>
      </div>

      <b-form ref="form">
        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('reservation.source')"
              rules="required"
            >
              <b-form-group>
                <template #label>
                  <div>
                    {{ $t('reservation.source') }}
                    <span class="text-danger">(*)</span>
                  </div>
                </template>
                <v-select
                  id="airlines-search"
                  v-model="sourceData"
                  class="w-100"
                  :state="getValidationState(validationContext) === false ? false : null"
                  :reduce="val => val"
                  :options="distributorsList"
                  label="label"
                  :placeholder="$t('reservation.inputSourcePlaceholder')"
                >
                  <template #selected-option="data">
                    <span>{{ $te(`flight.airlines.${data.label}`) ? `${$t(`flight.airlines.${data.label}`)} (${$t(`flight.sourceName.${data.label}`)})` : data.label }}</span>
                  </template>
                  <template #option="data">
                    <span>{{ $te(`flight.airlines.${data.label}`) ? `${$t(`flight.airlines.${data.label}`)} (${$t(`flight.sourceName.${data.label}`)})` : data.label }}</span>
                  </template>
                </v-select>

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('reservation.pnrCode')"
              rules="required"
            >
              <b-form-group>
                <template #label>
                  <div>
                    {{ $t('reservation.pnrCode') }}
                    <span class="text-danger">(*)</span>
                  </div>
                </template>
                <b-form-input
                  v-model="pnrCodeData"
                  :state="getValidationState(validationContext) === false ? false : null"
                  maxlength="6"
                  :formatter="removeAccentsUpperCaseFormatter"
                  :placeholder="$t('reservation.inputPnrPlaceholder')"
                  @input="val=> val.toUpperCase()"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row v-if="sourceData === 'F1'">
          <b-col
            cols="12"
            md="12"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('reservation.airline')"
              rules="required"
            >
              <b-form-group>
                <template #label>
                  <div>
                    {{ $t('reservation.airline') }}
                    <span class="text-danger">(*)</span>
                  </div>
                </template>
                <b-form-input
                  v-model="airlineData"
                  :state="getValidationState(validationContext) === false ? false : null"
                  maxlength="2"
                  :formatter="removeAccentsUpperCaseFormatter"
                  :placeholder="$t('reservation.inputPnrPlaceholder')"
                  @input="val=> val.toUpperCase()"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <!-- ANCHOR: Open history -->
        <b-form-checkbox
          id="open-history"
          v-model="isCreateBooking"
          name="open-history"
        >
          <h4 class="mb-0">
            <b><span class="text-danger">Đồng bộ dữ liệu</span> (Vui lòng chọn vào đây nếu muốn thay đổi booking)</b>
          </h4>
        </b-form-checkbox>
        <div v-if="isCreateBooking">
          <h5 class="mt-50">
            {{ $t('reservation.chooseAgencyOrCustomerTitle') }}:
          </h5>
          <b-row>
            <!-- ANCHOR Select Agency -->
            <b-col
              cols="12"
              md="12"
            >
              <validation-provider
                #default="validationContext"
                :name="$t('reservation.agency')"
                rules=""
              >
                <b-form-group>
                  <template #label>
                    <div>
                      {{ $t('reservation.agency') }}
                      <!-- <span class="text-danger">(*)</span> -->
                    </div>
                  </template>
                  <v-select
                    id="agency-to-assign"
                    v-model="agencyToAssign"
                    class="w-100"
                    :state="getValidationState(validationContext) === false ? false : null"
                    :reduce="val => val"
                    :options="agencyToAssignOptions"
                    label="agencyCode"
                    :disabled="!isRoleF1"
                    :loading="loadingAgencies"
                    :filterable="false"
                    :placeholder="$t('reservation.placeholderSelect')"
                    @open="handleOpenAgency"
                    @search="handleSearchAgency"
                    @input="handleInputAgency"
                  >
                    <template #selected-option="{agencyName, agencyCode}">
                      <div>
                        <span class="d-block font-weight-bold text-truncate">
                          {{ agencyName }} <small>({{ agencyCode }})</small>
                        </span>
                      </div>
                    </template>
                    <template #option="{agencyName, agencyCode}">
                      <div>
                        <span class="d-block font-weight-bold text-truncate">
                          {{ agencyName }} <small>({{ agencyCode }})</small>
                        </span>
                      </div>
                    </template>
                    <template #spinner="{ loading }">
                      <div
                        v-if="loading"
                        style="border-left-color: rgba(88, 151, 251, 0.71)"
                        class="vs__spinner"
                      />
                    </template>
                    <template #no-options>
                      {{ $t('noOptions') }}
                    </template>
                  </v-select>

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- ANCHOR select Customer -->
            <b-col
              cols="12"
              md="12"
            >
              <validation-provider
                #default="validationContext"
                :name="$t('reservation.customer')"
                rules=""
              >
                <b-form-group>
                  <template #label>
                    <div>
                      {{ $t('reservation.customer') }}
                      <!-- <span class="text-danger">(*)</span> -->
                    </div>
                  </template>
                  <v-select
                    v-model="customerToAssign"
                    class="w-100"
                    :state="getValidationState(validationContext) === false ? false : null"
                    :reduce="val => val"
                    label="code"
                    :filterable="false"
                    :loading="loadingCustomers"
                    :options="customerToAssignOptions"
                    :disabled="!isRoleF1"
                    :placeholder="$t('reservation.placeholderSelect')"
                    @open="handleOpenCustomer"
                    @search="handleSearchCustomer"
                    @input="handleInputCustomer"
                  >
                    <template #selected-option="{lastName, firstName, code}">
                      <div>
                        <span class="d-block font-weight-bold text-truncate">
                          {{ `${lastName} ${firstName}` }} <small>({{ code }})</small>
                        </span>
                      </div>
                    </template>
                    <template #option="{lastName, firstName, code}">
                      <div>
                        <span class="d-block font-weight-bold text-truncate">
                          {{ `${lastName} ${firstName}` }} <small>({{ code }})</small>
                        </span>
                      </div>
                    </template>
                    <template #spinner="{ loading }">
                      <div
                        v-if="loading"
                        style="border-left-color: rgba(88, 151, 251, 0.71)"
                        class="vs__spinner"
                      />
                    </template>
                    <template #no-options>
                      {{ $t('noOptions') }}
                    </template>
                  </v-select>

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- ANCHOR: is hide fare booking -->
          <b-form-checkbox
            v-if="agencyToAssign && isRoleF1 && canHideFareBooking"
            v-model="isHideFare"
          >
            <div class="fw-700 text-16px d-flex-center justify-content-start">
              <span class="text-danger">Ẩn giá booking với đại lý F2, F3</span>
            </div>
          </b-form-checkbox>
        </div>
      </b-form>

      <div class="mt-1 d-flex-center gap-2">
        <b-button
          variant="gradient"
          pill
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          :disabled="invalid || pristine"
          @click="confirmFindPnrCode"
        >
          {{ $t('reservation.find') }}
        </b-button>
      </div>
    </b-card>
  </validation-observer>
</template>

<script>
import {
  BButton, BFormInput, BFormInvalidFeedback, BFormGroup, BRow, BCol, BForm, BFormCheckbox, BCard,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import {
  computed, ref, onUnmounted, watch,
} from '@vue/composition-api'
import vSelect from 'vue-select'
import debounce from 'lodash/debounce'
import Vue from 'vue'

import { useRouter } from '@/@core/utils/utils'
import store from '@/store'
import env from '@/libs/env'

import { removeAccentsUpperCaseFormatter } from '@core/comp-functions/forms/formatter-input'
import formValidation from '@core/comp-functions/forms/form-validation'

import useReservationHandle from '@reservation/useReservationHandle'

import { required } from '@validations'

import reservationStoreModule from '../reservationStoreModule'

export default {
  components: {
    BCard,
    BButton,
    BFormInput,
    BFormInvalidFeedback,
    BFormGroup,
    BRow,
    BCol,
    BForm,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,

    vSelect,
  },

  setup(_, { root }) {
    const RESERVATION_APP_STORE_MODULE_NAME = 'app-reservation'
    if (!store.hasModule(RESERVATION_APP_STORE_MODULE_NAME)) {
      store.registerModule(RESERVATION_APP_STORE_MODULE_NAME, reservationStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(RESERVATION_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(RESERVATION_APP_STORE_MODULE_NAME)
      }
    })

    store.dispatch('globalConfig/getAvailableDistributorSource')
    const distributorsList = computed(() => store.getters['globalConfig/getAllDistributors'].filter(d => !env.removeSourcesInFindPnrOutSystem?.includes(d)))
    const { refFormObserver, getValidationState } = formValidation()
    const pnrCodeData = ref()
    const sourceData = ref('VN1A') // Mặc định VN1A
    const airlineData = ref() // for F1
    const isCreateBooking = ref(false)
    const isHideFare = ref(false) // ẩn giá cho F2
    const customerToAssign = ref()
    const agencyToAssign = ref()
    const agencyToAssignOptions = ref([])
    const agencyOptionsDefault = ref([])
    const customerToAssignOptions = ref([])
    const customerOptionsDefault = ref([])
    const {
      retrieveBooking, fetchAgencies, fetchCustomers, loadingAgencies, loadingCustomers,
    } = useReservationHandle()

    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    const meData = computed(() => store.getters['userStore/getMeData'])

    const { router } = useRouter()
    function handleFindPnrCode() {
      root.$bvModal.show('modal-api-loading')

      const payload = {
        pnrNumber: pnrCodeData.value,
        source: sourceData.value,
        airline: airlineData.value,
        isCreateBooking: isCreateBooking.value,
        isHideFare: isHideFare.value,
        customerId: customerToAssign.value?.id || undefined,
        agencyId: agencyToAssign.value?.id || undefined,
      }

      retrieveBooking(payload)
        .then(res => {
          const { id: idBooking, bookingInside, createdBy } = res
          const isShowGoToBooking = isRoleF1.value || meData.value?.agency?.id === createdBy?.agency?.id || meData.value?.agency?.id === createdBy?.agency?.parentAgency?.id
          if (bookingInside) {
            Vue.swal({
              title: 'Đồng bộ không thành công!',
              html: `
                  <div class="text-20px font-weight-bolder">
                    Code đã tồn tại trong hệ thống, không thể đồng bộ cho người dùng khác.
                  </div>`,
              icon: 'warning',
              iconColor: '#EF5350',
              showCancelButton: true,
              showConfirmButton: isShowGoToBooking,
              allowEscapeKey: false,
              allowOutsideClick: false,
              cancelButtonText: 'Đóng',
              confirmButtonText: 'Đi tới booking đã tồn tại',
              customClass: {
                confirmButton: 'btn btn-outline-warning',
                cancelButton: 'btn btn-flat-danger ml-50',
                title: 'text-20px font-weight-bolder text-danger',
              },
              buttonsStyling: false,
            }).then(result => {
              if (result.value) {
                router.push({ name: 'apps-reservations-modify', params: { id: `${idBooking}` } })
              }
            })
          } else if (idBooking) {
            router.push({ name: 'apps-reservations-modify', params: { id: `${idBooking}` } })
          } else {
            router.push({ name: 'apps-reservations-modify', params: { id: `${sourceData.value}-${pnrCodeData.value}${airlineData.value ? `-${airlineData.value}` : ''}` } })
          }
        })
        .finally(() => {
          root.$bvModal.hide('modal-api-loading')
        })
    }

    function confirmFindPnrCode() {
      if (isCreateBooking.value) {
        const assignString = agencyToAssign.value
          ? `${this.$t('Agency')} ${agencyToAssign.value?.agencyCode} (${agencyToAssign.value.agencyName})`
          : customerToAssign.value
            ? `${this.$t('customers')} ${customerToAssign.value?.code} (${customerToAssign.value.lastName} ${customerToAssign.value.firstName})`
            : `chính mình (${meData.value?.username || `${meData.value?.lastName} ${meData.value?.firstName}`})`
        const titleMsg = () => this.$createElement('div', {
          domProps: {
            innerHTML: `
            <span class="font-weight-bold text-16px">Anh/chị chắc chắn muốn đồng bộ code <code class="code-agency">${pnrCodeData.value}</code> cho <span class="font-weight-bolder text-danger">${assignString}</span>?</span>
          `,
          },
        })
        root.$bvModal
          .msgBoxConfirm(titleMsg(), {
            title: this.$t('modal.confirm'),
            size: 'md',
            okVariant: 'info',
            okTitle: this.$t('modal.iamConfirm'),
            cancelTitle: this.$t('modal.close'),
            cancelVariant: 'outline-danger',
            hideHeaderClose: true,
            centered: true,
          })
          .then(value => {
            if (value) {
              handleFindPnrCode()
            }
          })
      } else {
        handleFindPnrCode()
      }
    }

    function handleOpenAgency() {
      if (agencyOptionsDefault.value && agencyOptionsDefault.value.length === 0) {
        fetchAgencies()
          .then(res => {
            agencyToAssignOptions.value = res
            agencyOptionsDefault.value = res
          })
      } else {
        agencyToAssignOptions.value = agencyOptionsDefault.value
      }
    }

    const handleSearchAgency = debounce(search => {
      if (search) {
        fetchAgencies(search).then(res => {
          agencyToAssignOptions.value = res
        })
      }
    }, 300)

    function handleInputAgency() {
      customerToAssign.value = null
    }

    function handleOpenCustomer() {
      if (customerOptionsDefault.value && customerOptionsDefault.value.length === 0) {
        fetchCustomers()
          .then(res => {
            customerToAssignOptions.value = res
            customerOptionsDefault.value = res
          })
      } else {
        customerToAssignOptions.value = customerOptionsDefault.value
      }
    }

    const handleSearchCustomer = debounce(search => {
      if (search) {
        fetchCustomers(search).then(res => {
          customerToAssignOptions.value = res
        })
      }
    }, 300)

    function handleInputCustomer() {
      agencyToAssign.value = null
    }

    watch(() => [isCreateBooking.value], ([newValue]) => {
      if (newValue) {
        if (!isRoleF1.value) {
          const agency = store.getters['userStore/getAgencyData']
          agencyToAssign.value = agency
        }
      } else {
        agencyToAssign.value = null
      }
    })

    return {
      pnrCodeData,
      sourceData,
      refFormObserver,
      getValidationState,
      removeAccentsUpperCaseFormatter,
      isCreateBooking,
      distributorsList,
      confirmFindPnrCode,

      isRoleF1,

      // agency
      agencyToAssign,
      agencyToAssignOptions,
      handleOpenAgency,
      handleSearchAgency,
      handleInputAgency,

      // customer
      customerToAssign,
      customerToAssignOptions,
      handleOpenCustomer,
      handleSearchCustomer,
      handleInputCustomer,

      loadingAgencies,
      loadingCustomers,
      airlineData,
      required,
      isHideFare,
      canHideFareBooking: env.canHideFareBooking,
    }
  },
}
</script>
