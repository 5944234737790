var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid,
          pristine = _ref.pristine;
        return [_c('b-card', {
          attrs: {
            "id": "find-booking-by-pnr-code-page"
          }
        }, [_c('div', [_c('h4', {
          staticClass: "text-heading-3 m-0"
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.findBookingByPnrCode')) + " ")])]), _c('b-form', {
          ref: "form"
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "12"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('reservation.source'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('reservation.source')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('v-select', {
                staticClass: "w-100",
                attrs: {
                  "id": "airlines-search",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "reduce": function reduce(val) {
                    return val;
                  },
                  "options": _vm.distributorsList,
                  "label": "label",
                  "placeholder": _vm.$t('reservation.inputSourcePlaceholder')
                },
                scopedSlots: _vm._u([{
                  key: "selected-option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(_vm._s(_vm.$te("flight.airlines.".concat(data.label)) ? "".concat(_vm.$t("flight.airlines.".concat(data.label)), " (").concat(_vm.$t("flight.sourceName.".concat(data.label)), ")") : data.label))])];
                  }
                }, {
                  key: "option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(_vm._s(_vm.$te("flight.airlines.".concat(data.label)) ? "".concat(_vm.$t("flight.airlines.".concat(data.label)), " (").concat(_vm.$t("flight.sourceName.".concat(data.label)), ")") : data.label))])];
                  }
                }], null, true),
                model: {
                  value: _vm.sourceData,
                  callback: function callback($$v) {
                    _vm.sourceData = $$v;
                  },
                  expression: "sourceData"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1)], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "12"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('reservation.pnrCode'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('reservation.pnrCode')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "maxlength": "6",
                  "formatter": _vm.removeAccentsUpperCaseFormatter,
                  "placeholder": _vm.$t('reservation.inputPnrPlaceholder')
                },
                on: {
                  "input": function input(val) {
                    return val.toUpperCase();
                  }
                },
                model: {
                  value: _vm.pnrCodeData,
                  callback: function callback($$v) {
                    _vm.pnrCodeData = $$v;
                  },
                  expression: "pnrCodeData"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1)], 1), _vm.sourceData === 'F1' ? _c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "12"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('reservation.airline'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('reservation.airline')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "maxlength": "2",
                  "formatter": _vm.removeAccentsUpperCaseFormatter,
                  "placeholder": _vm.$t('reservation.inputPnrPlaceholder')
                },
                on: {
                  "input": function input(val) {
                    return val.toUpperCase();
                  }
                },
                model: {
                  value: _vm.airlineData,
                  callback: function callback($$v) {
                    _vm.airlineData = $$v;
                  },
                  expression: "airlineData"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1)], 1) : _vm._e(), _c('b-form-checkbox', {
          attrs: {
            "id": "open-history",
            "name": "open-history"
          },
          model: {
            value: _vm.isCreateBooking,
            callback: function callback($$v) {
              _vm.isCreateBooking = $$v;
            },
            expression: "isCreateBooking"
          }
        }, [_c('h4', {
          staticClass: "mb-0"
        }, [_c('b', [_c('span', {
          staticClass: "text-danger"
        }, [_vm._v("Đồng bộ dữ liệu")]), _vm._v(" (Vui lòng chọn vào đây nếu muốn thay đổi booking)")])])]), _vm.isCreateBooking ? _c('div', [_c('h5', {
          staticClass: "mt-50"
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.chooseAgencyOrCustomerTitle')) + ": ")]), _c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "12"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('reservation.agency'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('reservation.agency')) + " ")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('v-select', {
                staticClass: "w-100",
                attrs: {
                  "id": "agency-to-assign",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "reduce": function reduce(val) {
                    return val;
                  },
                  "options": _vm.agencyToAssignOptions,
                  "label": "agencyCode",
                  "disabled": !_vm.isRoleF1,
                  "loading": _vm.loadingAgencies,
                  "filterable": false,
                  "placeholder": _vm.$t('reservation.placeholderSelect')
                },
                on: {
                  "open": _vm.handleOpenAgency,
                  "search": _vm.handleSearchAgency,
                  "input": _vm.handleInputAgency
                },
                scopedSlots: _vm._u([{
                  key: "selected-option",
                  fn: function fn(_ref2) {
                    var agencyName = _ref2.agencyName,
                      agencyCode = _ref2.agencyCode;
                    return [_c('div', [_c('span', {
                      staticClass: "d-block font-weight-bold text-truncate"
                    }, [_vm._v(" " + _vm._s(agencyName) + " "), _c('small', [_vm._v("(" + _vm._s(agencyCode) + ")")])])])];
                  }
                }, {
                  key: "option",
                  fn: function fn(_ref3) {
                    var agencyName = _ref3.agencyName,
                      agencyCode = _ref3.agencyCode;
                    return [_c('div', [_c('span', {
                      staticClass: "d-block font-weight-bold text-truncate"
                    }, [_vm._v(" " + _vm._s(agencyName) + " "), _c('small', [_vm._v("(" + _vm._s(agencyCode) + ")")])])])];
                  }
                }, {
                  key: "spinner",
                  fn: function fn(_ref4) {
                    var loading = _ref4.loading;
                    return [loading ? _c('div', {
                      staticClass: "vs__spinner",
                      staticStyle: {
                        "border-left-color": "rgba(88, 151, 251, 0.71)"
                      }
                    }) : _vm._e()];
                  }
                }, {
                  key: "no-options",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.agencyToAssign,
                  callback: function callback($$v) {
                    _vm.agencyToAssign = $$v;
                  },
                  expression: "agencyToAssign"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "12"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('reservation.customer'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('reservation.customer')) + " ")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('v-select', {
                staticClass: "w-100",
                attrs: {
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "reduce": function reduce(val) {
                    return val;
                  },
                  "label": "code",
                  "filterable": false,
                  "loading": _vm.loadingCustomers,
                  "options": _vm.customerToAssignOptions,
                  "disabled": !_vm.isRoleF1,
                  "placeholder": _vm.$t('reservation.placeholderSelect')
                },
                on: {
                  "open": _vm.handleOpenCustomer,
                  "search": _vm.handleSearchCustomer,
                  "input": _vm.handleInputCustomer
                },
                scopedSlots: _vm._u([{
                  key: "selected-option",
                  fn: function fn(_ref5) {
                    var lastName = _ref5.lastName,
                      firstName = _ref5.firstName,
                      code = _ref5.code;
                    return [_c('div', [_c('span', {
                      staticClass: "d-block font-weight-bold text-truncate"
                    }, [_vm._v(" " + _vm._s("".concat(lastName, " ").concat(firstName)) + " "), _c('small', [_vm._v("(" + _vm._s(code) + ")")])])])];
                  }
                }, {
                  key: "option",
                  fn: function fn(_ref6) {
                    var lastName = _ref6.lastName,
                      firstName = _ref6.firstName,
                      code = _ref6.code;
                    return [_c('div', [_c('span', {
                      staticClass: "d-block font-weight-bold text-truncate"
                    }, [_vm._v(" " + _vm._s("".concat(lastName, " ").concat(firstName)) + " "), _c('small', [_vm._v("(" + _vm._s(code) + ")")])])])];
                  }
                }, {
                  key: "spinner",
                  fn: function fn(_ref7) {
                    var loading = _ref7.loading;
                    return [loading ? _c('div', {
                      staticClass: "vs__spinner",
                      staticStyle: {
                        "border-left-color": "rgba(88, 151, 251, 0.71)"
                      }
                    }) : _vm._e()];
                  }
                }, {
                  key: "no-options",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.customerToAssign,
                  callback: function callback($$v) {
                    _vm.customerToAssign = $$v;
                  },
                  expression: "customerToAssign"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1)], 1), _vm.agencyToAssign && _vm.isRoleF1 && _vm.canHideFareBooking ? _c('b-form-checkbox', {
          model: {
            value: _vm.isHideFare,
            callback: function callback($$v) {
              _vm.isHideFare = $$v;
            },
            expression: "isHideFare"
          }
        }, [_c('div', {
          staticClass: "fw-700 text-16px d-flex-center justify-content-start"
        }, [_c('span', {
          staticClass: "text-danger"
        }, [_vm._v("Ẩn giá booking với đại lý F2, F3")])])]) : _vm._e()], 1) : _vm._e()], 1), _c('div', {
          staticClass: "mt-1 d-flex-center gap-2"
        }, [_c('b-button', {
          attrs: {
            "variant": "gradient",
            "pill": "",
            "block": _vm.$store.getters['app/currentBreakPoint'] === 'xs',
            "disabled": invalid || pristine
          },
          on: {
            "click": _vm.confirmFindPnrCode
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.find')) + " ")])], 1)], 1)];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }